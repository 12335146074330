import styled from "@emotion/styled";
import { mq } from "styles/media-query";

export const InfoMain = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1440px;
  width: 100%;
  margin-top: -30px;

  ${mq["lg"]} {
    width: 90%;
  }

  ${mq["md"]} {
    flex-wrap: wrap;
  }
`;

export const InfoContent = styled.div`
  max-width: 528px;
  width: 100%;
  margin-right: 32px;

  ${mq["md"]} {
    margin-right: 0px;
    text-align: center;
    margin-bottom: 20px;
  }
`;

export const InfoImage = styled.div`
  max-width: 528px;
  width: 100%;
`;
