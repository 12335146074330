import React from "react";
import FaqBar from "./components";
import { FaqMain, PediatricToggleTitle } from "./Faq";

const Faq = ({ data }: any) => {
  return (
    <FaqMain>
      <br />
      <br />
      {data.length > 0 && (
        <PediatricToggleTitle>Frequently Asked Questions</PediatricToggleTitle>
      )}
      {data.map((item: any, i: any) => (
        <FaqBar answers={item.answer} question={item.question} key={i} />
      ))}
    </FaqMain>
  );
};

export default Faq;
