export const DentistImgData = [
  {
    image: "braces.png",
  },
  {
    image: "retainers.png",
  },
  {
    image: "partialexpanders.png",
  },
  {
    image: "headgear.png",
  },
];
