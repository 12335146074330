import React from "react";
import { theme } from "@/GlobalStyles";
import { Heading, Paragraph } from "styles/typography";
import Image from "../image";
import { InfoContent, InfoImage, InfoMain } from "./info-section";

interface Props {
  aboutInfo?: any;
  image?: string;
  heading?: string;
}

const InfoSection = ({ aboutInfo, image, heading }: Props) => {
  return (
    <InfoMain>
      <InfoContent>
        <div>
          {heading && (
            <Heading color={theme.colors.tomatoRed}>{heading}</Heading>
          )}
          {aboutInfo?.descriptionOne && (
            <Paragraph>{aboutInfo?.descriptionOne}</Paragraph>
          )}
          {aboutInfo?.list.length > 1 && (
            <ul>
              {aboutInfo.list.map((item: any, ind: any) => (
                <li key={ind}>{item}</li>
              ))}
            </ul>
          )}
          {aboutInfo?.descriptionTwo && (
            <Paragraph>{aboutInfo?.descriptionTwo}</Paragraph>
          )}
          {aboutInfo?.descriptionThree && (
            <Paragraph>{aboutInfo?.descriptionThree}</Paragraph>
          )}
          {aboutInfo?.descriptionFour && (
            <Paragraph>{aboutInfo?.descriptionFour}</Paragraph>
          )}
          {aboutInfo?.descriptionFive && (
            <Paragraph>{aboutInfo?.descriptionFive}</Paragraph>
          )}
        </div>
      </InfoContent>
      <InfoImage data-aos="fade-left">
        <Image filename={image} alt="info-image" />
      </InfoImage>
    </InfoMain>
  );
};

export default InfoSection;
