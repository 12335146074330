import React from "react";
import { AltLayout } from "layouts/AltLayout";
import InfoSection from "@/common/info-section";
import DentalProcedure from "./components/dental-procedure";
import Faq from "./components/faq/";
import { graphql } from "gatsby";
import { theme } from "@/GlobalStyles";
import AlternativeHeroBanner from "@/common/alternative-hero-banner";

const PediatricServices = ({ data }: any) => {
  const frontmatter = data.allFile.edges[0].node.childMdx.frontmatter;

  return (
    <AltLayout>
      <AlternativeHeroBanner
        IntroHeader={frontmatter.aboutPage.introHeader}
        NavigateTo={frontmatter.aboutPage.NavigateTo}
        NavigateFrom="Home"
        PageName={frontmatter.aboutPage.pageName}
        BgHero={frontmatter.aboutPage.background}
        introHeadColor={theme.colors.tomatoRed}
      />
      <InfoSection
        aboutInfo={frontmatter.aboutInfo}
        image={frontmatter.aboutInfo.image}
      />
      <DentalProcedure
        aboutServices={frontmatter.aboutServices}
        circles={frontmatter.circles}
        invitationDescription={frontmatter.invitationDescription}
      />
      <Faq data={frontmatter.faq} />
    </AltLayout>
  );
};

export default PediatricServices;

export const query = graphql`
  query ($slug: String!) {
    allFile(
      filter: {
        sourceInstanceName: { eq: "services" }
        childMdx: { frontmatter: { slug: { eq: $slug } } }
      }
    ) {
      edges {
        node {
          childMdx {
            frontmatter {
              aboutPage {
                NavigateTo
                introHeader
                pageName
                background
              }
              slug
              aboutInfo {
                descriptionFour
                descriptionOne
                descriptionThree
                descriptionTwo
                descriptionFive
                image
                list
              }
              circles
              invitationDescription
              aboutServices {
                title
                starterText
                content {
                  boldText
                  normalText
                  list
                }
              }
              faq {
                answer
                question
              }
            }
          }
        }
      }
    }
  }
`;
