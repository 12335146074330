import Image from "@/common/image";
import React, { useState } from "react";
import {
  PediatricToggleCollapse,
  PediatricToggleContainer,
  PediatricToggleQuestion,
  PlusImage,
  ToggleAnswer,
  ToggleAnswerContainer,
} from "./faq-bar";
import Collapsible from "react-collapsible";

const FaqBar = ({ question, answers }: any) => {
  const [toggle, setToggle] = useState(false);
  const toggleIsLoading = () => {
    setToggle((current) => !current);
  };
  return (
    <PediatricToggleContainer>
      <br />
      <Collapsible
        trigger={
          <PediatricToggleCollapse onClick={toggleIsLoading}>
            <PediatricToggleQuestion>{question}</PediatricToggleQuestion>

            <PlusImage>
              <Image
                filename="plus.png"
                alt="plus"
                width="40px"
                style={{
                  transform: toggle ? "rotate(-44deg)" : "rotate(0deg)",
                  transition: " 0.5s",
                }}
              />
            </PlusImage>
          </PediatricToggleCollapse>
        }
      >
        <ToggleAnswerContainer>
          {answers.map((answer: string, ind: string) => (
            <ToggleAnswer key={ind}>{answer}</ToggleAnswer>
          ))}
        </ToggleAnswerContainer>
      </Collapsible>
    </PediatricToggleContainer>
  );
};

export default FaqBar;
