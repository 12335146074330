import styled from "@emotion/styled";
import { mq } from "styles/media-query";

export const PediatricToggleTitle = styled.div`
  width: 90%;
  text-align: left;
  margin-top: 32px;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 700;
  font-size: 48px;
  line-height: 72px;
  color: #b56959;
  ${mq["md"]} {
    font-size: 26px;
    line-height: 26px;
  }
`;

export const FaqMain = styled.div`
  max-width: 1440px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
