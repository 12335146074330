import styled from "@emotion/styled";
import { mq } from "styles/media-query";

export const PediatricToggleContainer = styled.div`
  max-width: 1089px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-bottom: 1px solid;
  border-color: #b56959;
`;

export const PediatricToggleCollapse = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const PediatricToggleQuestion = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 38.86px;
  color: #b56959;
  margin-top: 32px;
  margin-bottom: 32px;
  ${mq["md"]} {
    font-size: 14px;
    line-height: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

export const PlusImage = styled.div<{
  rotate?: string;
}>`
  transform: ${(props) => props.rotate || "rotate(0deg)"};
  width: 40px;
  margin-bottom: 32px;
  margin-top: 32px;
`;
export const ToggleAnswerContainer = styled.div`
  margin-bottom: 32px;
  ${mq["md"]} {
    margin-bottom: 12px;
  }
`;
export const ToggleAnswer = styled.div`
  margin-top: 30px;

  animation-duration: 0.7s;
  font-size: 16px;
  line-height: 25px;
  ${mq["md"]} {
    margin-top: 0px;
    font-size: 14px;
  }
`;
